import { TextColor } from '@lyra/core/utils/text'
import React from 'react'
import { StackProps, XStack } from 'tamagui'

import { getDefaultButtonIconSize } from '../Button'
import Icon, { SVGIcon } from '../Icon'
import VideoText, { VideoTextProps } from '../VideoText'

export type TagProps = {
  label?: React.ReactNode
  icon?: SVGIcon | null
  rightIcon?: SVGIcon | null
  color?: TextColor
  onPress?: () => void
  textTransform?: VideoTextProps['textTransform']
  isLarge?: boolean
  isSelected?: boolean
} & StackProps

const Tag = ({
  label,
  icon,
  rightIcon,
  color,
  onPress,
  textTransform,
  isLarge,
  isSelected,
  ...stackProps
}: TagProps) => {
  return (
    <XStack
      borderWidth={1}
      borderColor={isSelected ? '$primaryText' : '$hairline'}
      cursor={onPress ? 'pointer' : undefined}
      backgroundColor="$translucentBg"
      hoverStyle={
        onPress
          ? {
              backgroundColor: '$hoverBg',
            }
          : undefined
      }
      pressStyle={
        onPress
          ? {
              backgroundColor: '$pressBg',
            }
          : undefined
      }
      paddingHorizontal={isLarge ? '$2' : '$1'}
      paddingVertical={isLarge ? '$1' : '$0.5'}
      minHeight={isLarge ? '$md' : '$sm'}
      gap={isLarge ? '$1.5' : '$1'}
      alignItems="center"
      width="fit-content"
      onPress={onPress}
      {...stackProps}
    >
      {icon ? <Icon icon={icon} size={14} color={color} /> : null}
      {label ? (
        typeof label === 'string' || typeof label === 'number' ? (
          <VideoText size={isLarge ? 'md' : 'sm'} textTransform={textTransform} color={color}>
            {label}
          </VideoText>
        ) : (
          label
        )
      ) : null}
      {rightIcon ? (
        <Icon
          icon={rightIcon}
          size={getDefaultButtonIconSize(isLarge ? 'md' : 'sm')}
          color={color}
        />
      ) : null}
    </XStack>
  )
}

export default Tag
