import React, { SVGProps } from 'react'

export default function ArbitrumIcon({ color, ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.855469 5.47625V12.5379C0.855469 12.9919 1.09326 13.4026 1.48958 13.6332L7.60727 17.164C7.99638 17.3874 8.47917 17.3874 8.86828 17.164L14.986 13.6332C15.3751 13.4098 15.6201 12.9919 15.6201 12.5379V5.47625C15.6201 5.02229 15.3823 4.61156 14.986 4.38098L8.86828 0.850151C8.47917 0.626773 7.99638 0.626773 7.60727 0.850151L1.48958 4.38098C1.10046 4.60435 0.862675 5.02229 0.862675 5.47625H0.855469Z"
        fill="#213147"
      />
      <path
        d="M9.56682 10.3766L8.69492 12.7689C8.67331 12.8337 8.67331 12.9058 8.69492 12.9779L10.1937 17.0923L11.9303 16.0907L9.84785 10.3766C9.79741 10.2469 9.61726 10.2469 9.56682 10.3766Z"
        fill="#12AAFF"
      />
      <path
        d="M11.3176 6.35558C11.2671 6.22587 11.087 6.22587 11.0365 6.35558L10.1647 8.74789C10.143 8.81274 10.143 8.8848 10.1647 8.95686L12.6218 15.6943L14.3584 14.6926L11.3176 6.36278V6.35558Z"
        fill="#12AAFF"
      />
      <path
        d="M8.23399 1.11689C8.27722 1.11689 8.32046 1.13131 8.35649 1.15292L14.9714 4.97198C15.0506 5.01521 15.0939 5.10168 15.0939 5.18815V12.8263C15.0939 12.9127 15.0434 12.9992 14.9714 13.0424L8.35649 16.8615C8.32046 16.8831 8.27722 16.8975 8.23399 16.8975C8.19075 16.8975 8.14752 16.8831 8.11149 16.8615L1.4966 13.0424C1.41733 12.9992 1.3741 12.9127 1.3741 12.8263V5.18094C1.3741 5.09448 1.42454 5.00801 1.4966 4.96477L8.11149 1.14572C8.14752 1.1241 8.19075 1.10969 8.23399 1.10969V1.11689ZM8.23399 0C7.9962 0 7.76561 0.0576461 7.54944 0.180144L0.934548 3.9992C0.509408 4.2442 0.25 4.69095 0.25 5.18094V12.8191C0.25 13.309 0.509408 13.7558 0.934548 14.0008L7.54944 17.8199C7.75841 17.9424 7.9962 18 8.23399 18C8.47178 18 8.70236 17.9424 8.91854 17.8199L15.5334 14.0008C15.9586 13.7558 16.218 13.309 16.218 12.8191V5.18094C16.218 4.69095 15.9586 4.2442 15.5334 3.9992L8.91133 0.180144C8.70236 0.0576461 8.46457 0 8.22678 0H8.23399Z"
        fill="#9DCCED"
      />
      <path
        d="M3.85254 15.701L4.46503 14.0293L5.69001 15.0453L4.54429 16.0974L3.85254 15.701Z"
        fill="#213147"
      />
      <path
        d="M7.67186 4.64051H5.99291C5.87041 4.64051 5.75512 4.71977 5.71189 4.83506L2.11621 14.6925L3.8528 15.6941L7.81597 4.83506C7.852 4.73418 7.77994 4.6333 7.67906 4.6333L7.67186 4.64051Z"
        fill="white"
      />
      <path
        d="M10.6122 4.64051H8.93323C8.81073 4.64051 8.69544 4.71977 8.65221 4.83506L4.54492 16.0905L6.28151 17.0921L10.7491 4.83506C10.7851 4.73418 10.7131 4.6333 10.6122 4.6333V4.64051Z"
        fill="white"
      />
    </svg>
  )
}
