import {
  arbitrumChain,
  baseChain,
  lyraChain,
  mainnetChain,
  optimismChain,
} from '../constants/chains'

export default function getChainForChainId(chainId: number) {
  switch (chainId) {
    case mainnetChain.id:
      return mainnetChain
    case lyraChain.id:
      return lyraChain
    case optimismChain.id:
      return optimismChain
    case arbitrumChain.id:
      return arbitrumChain
    case baseChain.id:
      return baseChain
    default: {
      console.warn(`Unexpected chain ID: ${chainId}`)
      return null
    }
  }
}
