import React from 'react'
import { StackProps, YStack } from 'tamagui'

type Props = StackProps

export default function Card({ children, ...stackProps }: Props) {
  return (
    <YStack
      padding="$2"
      borderColor={'$hairline'}
      borderWidth={1}
      borderRadius="$1"
      backgroundColor="$translucentBg"
      {...stackProps}
    >
      {children}
    </YStack>
  )
}
