import { forwardRef, useContext } from 'react'
import React from 'react'
import { Stack, StackProps } from 'tamagui'

import { DropdownContext } from '../Dropdown'

const DropdownClose = forwardRef<HTMLButtonElement, StackProps>(
  ({ children, ...stackProps }: StackProps, ref) => {
    const { setIsOpen } = useContext(DropdownContext)

    return (
      <Stack
        ref={ref}
        {...stackProps}
        onPress={() => {
          setIsOpen(false)
        }}
      >
        {children}
      </Stack>
    )
  }
)

DropdownClose.displayName = 'DropdownClose'

export default DropdownClose
