import {
  autoUpdate,
  FloatingFocusManager,
  FloatingList,
  FloatingPortal,
  Placement,
  safePolygon,
  shift,
  size,
  Strategy,
  useClick,
  useDismiss,
  useFloating,
  UseFloatingReturn,
  useHover,
  useInteractions,
  UseInteractionsReturn,
  useListNavigation,
} from '@floating-ui/react'
import React, { cloneElement, createContext, ReactElement, useCallback, useState } from 'react'
import { flushSync } from 'react-dom'
import { Stack, StackProps, withStaticProperties } from 'tamagui'

import DropdownClose from './DropdownClose'
import DropdownListItem from './DropdownListItem'

export interface DropdownContextValue {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  useInteractionsReturn: UseInteractionsReturn
  useFloatingReturn: UseFloatingReturn
  activeIndex: number | null
}

export const DropdownContext = createContext<DropdownContextValue>({} as DropdownContextValue)

export type DropdownProps = {
  trigger: React.ReactNode
  children: React.ReactNode
  onChangeOpen?: (isOpen: boolean) => void
  placement?: Placement
  openOnHover?: boolean
  triggerParentProps?: StackProps
  // Use 'fixed' strategy in Modals
  strategy?: Strategy
  portalId?: string
  isPortaled?: boolean
} & StackProps

const Dropdown = ({
  trigger,
  children,
  strategy,
  portalId,
  isPortaled,
  onChangeOpen,
  placement = 'bottom-end',
  openOnHover = false,
  ...stackProps
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [maxHeight, setMaxHeight] = useState<number | null>(null)
  const [maxWidth, setMaxWidth] = useState<number | null>(null)

  const [minWidth, setMinWidth] = useState<number | null>(null)
  const listRef = React.useRef<Array<HTMLElement | null>>([])

  const handleOnChangeOpen = useCallback(
    (isOpen: boolean) => {
      setIsOpen(isOpen)
      if (onChangeOpen) {
        onChangeOpen(isOpen)
      }
    },
    [onChangeOpen]
  )

  const useFloatingReturn = useFloating({
    placement,
    strategy: strategy ?? 'absolute',
    open: isOpen,
    onOpenChange: handleOnChangeOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      shift(),
      size({
        padding: 4,
        apply({ availableHeight, availableWidth, rects }) {
          flushSync(() => {
            setMaxHeight(availableHeight)
            setMinWidth(rects.reference.width)
            setMaxWidth(availableWidth)
          })
        },
      }),
    ],
  })

  const { context, floatingStyles, refs } = useFloatingReturn

  const click = useClick(context, {
    enabled: !openOnHover,
  })
  const hover = useHover(context, {
    enabled: openOnHover,
    handleClose: safePolygon(),
  })
  const dismiss = useDismiss(context)
  const listNavigation = useListNavigation(context, {
    activeIndex,
    onNavigate: setActiveIndex,
    listRef,
  })

  const useInteractionsReturn = useInteractions([click, hover, dismiss, listNavigation])
  const { getReferenceProps, getFloatingProps } = useInteractionsReturn

  const { zIndex } = { ...stackProps }

  const dropdownStyles: StackProps = {
    backgroundColor: '$hoverBg',
    borderColor: '$hairline',
    borderRadius: '$1',
    borderWidth: 1,
    maxHeight: strategy === 'fixed' && maxHeight ? maxHeight - 48 : maxHeight,
    maxWidth: maxWidth,
    minWidth: minWidth,
    focusStyle: { outlineWidth: 0 },
    zIndex: zIndex ?? '$floating',
    outlineWidth: 0,
  }

  const dropdownContextValue: DropdownContextValue = {
    useFloatingReturn,
    useInteractionsReturn,
    setIsOpen: handleOnChangeOpen,
    isOpen,
    activeIndex,
  }

  return (
    <DropdownContext.Provider value={dropdownContextValue}>
      {cloneElement(trigger as ReactElement, { ...getReferenceProps(), ref: refs.setReference })}
      {isPortaled || portalId ? (
        isOpen ? (
          <FloatingPortal id={portalId ? portalId : 'root'}>
            <FloatingFocusManager context={context} initialFocus={-1}>
              <FloatingList elementsRef={listRef}>
                <Stack
                  {...getFloatingProps()}
                  style={{ ...floatingStyles, overflowY: 'scroll' }}
                  {...dropdownStyles}
                  {...stackProps}
                  ref={refs.setFloating}
                  zIndex={10}
                >
                  {children}
                </Stack>
              </FloatingList>
            </FloatingFocusManager>
          </FloatingPortal>
        ) : null
      ) : isOpen ? (
        <FloatingFocusManager context={context} modal={false} initialFocus={-1}>
          <FloatingList elementsRef={listRef}>
            <Stack
              {...getFloatingProps()}
              style={{ ...floatingStyles, overflowY: 'scroll' }}
              {...dropdownStyles}
              ref={refs.setFloating}
              {...stackProps}
              display={!isOpen ? 'none' : 'unset'}
              zIndex={10}
            >
              {children}
            </Stack>
          </FloatingList>
        </FloatingFocusManager>
      ) : null}
    </DropdownContext.Provider>
  )
}

export default withStaticProperties(Dropdown, {
  ListItem: DropdownListItem,
  // Wrap a component with <Dropdown.Close> in order to close on press
  Close: DropdownClose,
})
