import React from 'react'
import { GetProps, Stack, Tooltip as TamaguiTooltip } from 'tamagui'

type Placement = GetProps<typeof TamaguiTooltip>['placement']

const getArrowPlacement = (placement: Placement) => {
  switch (placement) {
    case 'top-end':
      return {
        left: '85%',
        bottom: -4,
      }
    case 'top':
      return {
        left: '50%',
        bottom: -4,
        transform: 'translateX(-50%) rotate(45deg)',
      }

    case 'top-start':
      return {
        left: '15%',
        bottom: -4,
      }
    case 'bottom':
      return {
        top: -4,
        left: '50%',
        transform: 'translateX(-50%) rotate(45deg)',
      }
    case 'bottom-end':
      return {
        top: -4,
        left: '85%',
      }
    case 'bottom-start':
      return {
        top: -4,
        left: '15%',
      }
    case 'left':
      return {
        right: -4,
        top: '50%',
        transform: 'translateY(-50%) rotate(45deg)',
      }
    case 'right':
      return {
        left: -4,
        top: '50%',
      }
  }
}

const PopoverArrow = ({ placement }: { placement: Placement }) => {
  return (
    <Stack
      width={8}
      height={8}
      rotate="45deg"
      backgroundColor="$hoverBg"
      borderWidth={1}
      borderTopWidth={0}
      borderColor="$hairline"
      position="absolute"
      {...getArrowPlacement(placement)}
    />
  )
}

export default PopoverArrow
