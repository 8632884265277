import Button from '@lyra/core/components/Button'
import { Pathname } from '@lyra/web/constants/pages'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import React, { useEffect } from 'react'

import TrackButton, { TrackButtonProps } from './TrackButton'

const NextLinkButton = ({
  href,
  eventName,
  ...buttonProps
}: Omit<TrackButtonProps, 'href' | 'target'> & { href: Pathname }) => {
  const { prefetch } = useRouter()

  useEffect(() => {
    prefetch(href)
  }, [prefetch, href])

  return (
    <Link href={href} style={{ textDecoration: 'none' }} legacyBehavior>
      {eventName ? (
        <TrackButton {...buttonProps} href={href} eventName={eventName} />
      ) : (
        <Button {...buttonProps} href={href} />
      )}
    </Link>
  )
}
export default NextLinkButton
