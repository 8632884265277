import LabelText from '@lyra/core/components/LabelText'
import emptyFunction from '@lyra/core/utils/emptyFunction'
import React, { useRef, useState } from 'react'
import { GetProps, GetRef, StackProps, XStack, YStack } from 'tamagui'

import BodyText from '../BodyText'
import { getButtonMinHeight, getButtonPaddingHorizontal, getButtonPaddingVertical } from '../Button'
import { Input as StyledInput, InputContainer } from './styles'

type TamaguiInputProps = GetProps<typeof StyledInput>

export type InputRef = GetRef<typeof StyledInput> | null

export type InputSize = 'sm' | 'md' | 'lg'

export type InputProps = {
  value?: string | null
  placeholder?: string
  label?: string
  rightLabel?: string
  status?: 'error' | 'success' | 'default' | 'warning'
  size?: InputSize
  rightContent?: React.ReactNode
  leftContent?: React.ReactNode
  isDisabled?: boolean
  onChangeValue?: (value: string) => void
  onSubmit?: () => void
  onFocus?: () => void
  onBlur?: () => void
  isUpperCase?: boolean
  isPassword?: boolean
  autoFocus?: boolean
  returnKeyType?: TamaguiInputProps['enterKeyHint']
  inputMode?: TamaguiInputProps['inputMode']
  selectTextOnFocus?: boolean
  renderAsPlaceholder?: boolean
  textAlign?: 'left' | 'center' | 'right'
  maxLength?: number
} & StackProps

const Input = ({
  value,
  onChangeValue,
  size = 'md',
  placeholder,
  label,
  rightLabel,
  status,
  textAlign = 'left',
  isDisabled,
  rightContent,
  leftContent,
  isPassword,
  onSubmit,
  onFocus = emptyFunction,
  onBlur = emptyFunction,
  isUpperCase,
  autoFocus,
  returnKeyType,
  inputMode,
  selectTextOnFocus,
  renderAsPlaceholder,
  maxLength,
  ...stackProps
}: InputProps) => {
  const ref = useRef<InputRef>(null)
  const [isFocused, setIsFocused] = useState(false)

  return (
    <YStack gap="$1" {...stackProps}>
      {label ? (
        <XStack>
          <LabelText>{label}</LabelText>
          {rightLabel ? <BodyText marginLeft="auto">{rightLabel}</BodyText> : null}
        </XStack>
      ) : null}
      <InputContainer
        isFocused={isFocused}
        success={status === 'success'}
        failure={status === 'error'}
        warning={status === 'warning'}
        isDisabled={isDisabled}
        minWidth={getButtonMinHeight(size)}
        minHeight={getButtonMinHeight(size)}
        paddingVertical={getButtonPaddingVertical(size)}
        paddingHorizontal={getButtonPaddingHorizontal(size)}
        width="100%"
        onPress={() => {
          setIsFocused(true)
          ref.current?.focus()
        }}
      >
        {leftContent ? <XStack alignItems="center">{leftContent}</XStack> : null}
        <StyledInput
          ref={ref}
          size={size}
          height="100%"
          maxLength={maxLength ?? undefined}
          onChange={(e) => {
            if (onChangeValue) {
              onChangeValue(isUpperCase ? e.nativeEvent.text.toUpperCase() : e.nativeEvent.text)
            }
          }}
          autoFocus={autoFocus}
          secureTextEntry={isPassword}
          placeholder={placeholder && isUpperCase ? placeholder.toUpperCase() : placeholder}
          disabled={isDisabled}
          isDisabled={isDisabled}
          textAlign={textAlign}
          isPlaceholder={renderAsPlaceholder}
          value={value ?? undefined}
          onBlur={() => {
            setIsFocused(false)
            onBlur()
          }}
          onFocus={() => {
            setIsFocused(true)
            onFocus()
          }}
          onSubmitEditing={onSubmit}
          enterKeyHint={returnKeyType}
          inputMode={inputMode}
          selectTextOnFocus={selectTextOnFocus}
          $mobile={{
            fontSize: '16px',
          }}
        />
        {rightContent ? (
          <XStack
            onPress={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            {rightContent}
          </XStack>
        ) : null}
      </InputContainer>
    </YStack>
  )
}

export default Input
