import useIsMobile from '@lyra/core/hooks/useIsMobile'
import React, { ReactNode } from 'react'
import { GetProps, Popover, Tooltip as TamaguiTooltip, YStack } from 'tamagui'

import BodyText from '../BodyText'
import PopoverArrow from './PopoverArrow'

const MAX_WIDTH = 320

export type TooltipPlacement = GetProps<typeof TamaguiTooltip>['placement']

export type TooltipProps = {
  trigger: ReactNode
  title?: string
  content?: ReactNode
  onChangeOpen?: (isOpen: boolean) => void
  stayOpenOnHover?: boolean
  placement?: TooltipPlacement
}

const Tooltip = ({
  trigger,
  title,
  content,
  onChangeOpen,
  stayOpenOnHover = false,
  placement = 'top-start',
  ...tooltipProps
}: TooltipProps) => {
  const tooltipContent = (
    <>
      <PopoverArrow placement={placement} />
      <YStack
        backgroundColor="$hoverBg"
        borderColor="$hairline"
        borderWidth={1}
        borderRadius="$1"
        padding="$1.5"
        gap="$0.5"
        maxWidth={MAX_WIDTH}
      >
        {title ? <BodyText size="lg">{title}</BodyText> : null}
        {typeof content === 'string' ? <BodyText color="secondary">{content}</BodyText> : content}
      </YStack>
    </>
  )

  const isMobile = useIsMobile()

  if (!title && !content) {
    // no tooltip needs to be rendered
    return trigger
  }

  if (stayOpenOnHover || isMobile) {
    return (
      <Popover
        onOpenChange={onChangeOpen}
        placement={placement}
        hoverable={!isMobile}
        offset={8}
        {...tooltipProps}
      >
        <Popover.Trigger>{trigger}</Popover.Trigger>
        <Popover.Content
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
          animation="ease-regular"
        >
          <Popover.Close>{tooltipContent}</Popover.Close>
        </Popover.Content>
      </Popover>
    )
  } else {
    return (
      <TamaguiTooltip
        onOpenChange={onChangeOpen}
        placement={placement}
        restMs={100}
        offset={8}
        {...tooltipProps}
      >
        <TamaguiTooltip.Trigger>{trigger}</TamaguiTooltip.Trigger>
        <TamaguiTooltip.Content
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
          animation="ease-regular"
        >
          {tooltipContent}
        </TamaguiTooltip.Content>
      </TamaguiTooltip>
    )
  }
}

export default Tooltip
