import { getTokenImageUrl } from '@lyra/web/utils/tokens'
import Image from 'next/image'
import React from 'react'

type Props = {
  symbol: string
  size?: number
}

export default function TokenIcon({ symbol, size = 14 }: Props): React.ReactNode {
  return (
    <Image
      width={size}
      height={size}
      alt={symbol}
      src={getTokenImageUrl(symbol)}
      style={{ borderRadius: 100000 }}
    />
  )
}
