import React, { useState } from 'react'

import useHover from '../hooks/useHover'
import BodyText, { BodyTextProps } from './BodyText'
import Tooltip, { TooltipPlacement } from './Tooltip'

type Props = {
  label: React.ReactNode
  tooltipTitle?: string
  tooltipContent: React.ReactNode
  stayOpenOnHover?: boolean
  placement?: TooltipPlacement
} & Omit<BodyTextProps, 'children'>

export default function TooltipText({
  label,
  tooltipContent,
  tooltipTitle,
  stayOpenOnHover,
  size,
  placement,
  color: _color,
  ...textProps
}: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const { isHovering, handleHoverIn, handleHoverOut } = useHover()

  const color = _color ? _color : isHovering || isOpen ? 'primary' : 'secondary'

  return tooltipContent ? (
    <Tooltip
      content={tooltipContent}
      title={tooltipTitle}
      onChangeOpen={setIsOpen}
      stayOpenOnHover={stayOpenOnHover}
      placement={placement}
      trigger={
        <BodyText
          onHoverIn={handleHoverIn}
          onHoverOut={handleHoverOut}
          display="inline-flex"
          size={size}
          color={color}
          textDecorationLine={'underline'}
          textDecorationStyle="dotted"
          textDecorationColor={color}
          cursor="help"
          {...textProps}
        >
          {label}
        </BodyText>
      }
    />
  ) : (
    <BodyText display="inline-flex" size={size} color={color} {...textProps}>
      {label}
    </BodyText>
  )
}
