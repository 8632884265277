import { NumberInputProps } from '@lyra/core/components/Input/NumberInput'
import { bigNumberToString } from '@lyra/core/utils/bigNumberToString'
import emptyFunction from '@lyra/core/utils/emptyFunction'
import toBigNumber from '@lyra/core/utils/toBigNumber'
import React, { useCallback, useEffect, useState } from 'react'

import Input from '.'

type Props = {
  value: bigint
  decimals: number
  onChangeValue: (val: bigint) => void
  defaultValue?: bigint
  formatValue?: (value: bigint) => string
} & Omit<
  NumberInputProps,
  // note: we do not implement min/max/step intentionally
  'value' | 'onChangeValue' | 'defaultValue' | 'formatValue' | 'min' | 'max' | 'step'
>

export default function BigNumberInput({
  value,
  decimals,
  onChangeValue,
  formatValue: _formatValue,
  onFocus = emptyFunction,
  onBlur = emptyFunction,
  renderAsPlaceholder,
  defaultValue = BigInt(0),
  ...props
}: Props) {
  const formatValue = useCallback(
    (val: bigint) => (_formatValue ? _formatValue(val) : bigNumberToString(val, decimals)),
    [decimals, _formatValue]
  )
  // Raw string
  const [rawValue, setRawValue] = useState(
    value === BigInt(0) ? '' : bigNumberToString(value, decimals)
  )

  const [isFocused, setIsFocused] = useState(false)
  useEffect(() => {
    if (!isFocused) {
      // sync external state not coming from onChangeValue
      setRawValue(value === BigInt(0) ? '' : formatValue(value))
    }
  }, [value, formatValue, isFocused])

  const placeholder = formatValue(defaultValue)

  return (
    <Input
      {...props}
      value={rawValue}
      onChangeValue={(num) => {
        try {
          onChangeValue(toBigNumber(num, decimals))
          setRawValue(num)
        } catch (e) {}
      }}
      selectTextOnFocus
      status={props.status}
      onFocus={() => {
        setIsFocused(true)
        setRawValue(value === BigInt(0) ? '' : bigNumberToString(value, decimals))
        onFocus()
      }}
      onBlur={() => {
        setIsFocused(false)
        onBlur()
      }}
      placeholder={placeholder}
      renderAsPlaceholder={
        renderAsPlaceholder !== undefined
          ? renderAsPlaceholder && !isFocused
          : value > BigInt(0) && defaultValue === value && !isFocused
      }
    />
  )
}
