import BodyText from '@lyra/core/components/BodyText'
import Button from '@lyra/core/components/Button'
import Card from '@lyra/core/components/Card'
import { ArrowUpRight, ChevronDown, ChevronUp } from '@lyra/core/components/Icon'
import Link from '@lyra/core/components/Link'
import Section from '@lyra/core/components/Section'
import { SectionSpinnerStatus } from '@lyra/core/components/Section/SectionSpinner'
import CopyButton from '@lyra/web/components/common/CopyButton'
import NextLinkButton from '@lyra/web/components/common/NextLinkButton'
import { Pathname } from '@lyra/web/constants/pages'
import { TransactionStatus, TransactionStatusContext } from '@lyra/web/constants/transactions'
import getExplorerUrl from '@lyra/web/utils/getExplorerUrl'
import React, { useState } from 'react'
import { XStack } from 'tamagui'

type Props = {
  transactionStatus: TransactionStatus
  completeButtonProps?: {
    label: string
    path: Pathname
  }
  transactionStatusContext?: TransactionStatusContext
  disableClose?: boolean
  onPressComplete: () => void
}

const getSpinnerStatus = (walletTransactionStatus: TransactionStatus): SectionSpinnerStatus => {
  switch (walletTransactionStatus) {
    case 'complete':
      return 'complete'
    case 'switch-network':
    case 'paymaster':
    case 'confirm':
    case 'auto-execute':
      return 'action'
    case 'in-progress':
    case 'verify':
      return 'pending'
  }
}

export default function WalletTransactionProgressScreen({
  completeButtonProps,
  transactionStatus,
  transactionStatusContext,
  onPressComplete,
}: Props) {
  const [isVerifyOpen, setIsVerifyOpen] = useState(false)
  const showCompleteButton = transactionStatus === 'complete'

  return (
    <>
      <Section.Spinner height={180} status={getSpinnerStatus(transactionStatus)} width="100%" />

      <Section.YStack width="100%">
        {transactionStatusContext?.context ? (
          <BodyText color="secondary">
            {transactionStatusContext.context}
            {transactionStatusContext.contextLearnMoreHref ? (
              <>
                &nbsp;
                <Link
                  label="Learn more"
                  href={transactionStatusContext.contextLearnMoreHref}
                  target="_blank"
                />
              </>
            ) : null}
          </BodyText>
        ) : null}

        {transactionStatusContext?.contextLink ? (
          <Link size="md" {...transactionStatusContext.contextLink} />
        ) : transactionStatusContext?.chain && transactionStatusContext.txHash ? (
          <Link
            size="md"
            href={getExplorerUrl(transactionStatusContext.chain, transactionStatusContext.txHash)}
            target="_blank"
            label="View on Explorer"
          />
        ) : null}

        {transactionStatusContext?.uoHash && transactionStatusContext?.uo ? (
          <>
            <Button
              label="Advanced"
              icon={isVerifyOpen ? <ChevronUp /> : <ChevronDown />}
              onPress={() => setIsVerifyOpen(!isVerifyOpen)}
              size="sm"
              width="fit-content"
            />
            {isVerifyOpen ? (
              <Card
                alignItems="center"
                justifyContent="center"
                width="100%"
                paddingHorizontal="$3"
                gap="$2"
              >
                <BodyText width="100%" color="secondary" textAlign="center">
                  Verify this hash matches your wallet request:
                </BodyText>
                <BodyText textAlign="center" width="100%">
                  {transactionStatusContext.uoHash}
                </BodyText>
                <XStack alignItems="center" justifyContent="center" gap="$1.5" width="100%">
                  <CopyButton label="Copy" value={transactionStatusContext.uoHash} size="sm" />
                  <Button
                    label="Decode"
                    size="sm"
                    href={`/decode/${encodeURIComponent(
                      JSON.stringify(transactionStatusContext.uo)
                    )}`}
                    icon={<ArrowUpRight />}
                    target="_blank"
                  />
                </XStack>
              </Card>
            ) : null}
          </>
        ) : null}
      </Section.YStack>

      {showCompleteButton ? (
        <Section.YStack width="100%">
          {completeButtonProps?.path ? (
            <NextLinkButton
              size="lg"
              color="cta"
              label={completeButtonProps?.label ?? 'Done'}
              onPress={onPressComplete}
              width="100%"
              href={completeButtonProps.path}
            />
          ) : (
            <Button
              size="lg"
              color="cta"
              label={completeButtonProps?.label ?? 'Done'}
              onPress={onPressComplete}
              width="100%"
            />
          )}
        </Section.YStack>
      ) : null}
    </>
  )
}
