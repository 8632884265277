import React, { SVGProps } from 'react'

export default function BaseIcon({ color, ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.5 18C14.4706 18 18.5 13.9706 18.5 9C18.5 4.02944 14.4706 0 9.5 0C4.52944 0 0.5 4.02944 0.5 9C0.5 13.9706 4.52944 18 9.5 18Z"
        fill="#0052FF"
      />
      <path
        d="M9.54018 15.2542C13.0285 15.2542 15.8562 12.4313 15.8562 8.94912C15.8562 5.46692 13.0285 2.64404 9.54018 2.64404C6.2307 2.64404 3.51574 5.18494 3.24609 8.41913H11.5945V9.47912H3.24609C3.51574 12.7133 6.2307 15.2542 9.54018 15.2542Z"
        fill="white"
      />
    </svg>
  )
}
