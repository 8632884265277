import { useListItem, useMergeRefs } from '@floating-ui/react'
import { GestureReponderEvent } from '@tamagui/web'
import { forwardRef, useContext } from 'react'
import React from 'react'
import { XStack, YStack } from 'tamagui'

import BodyText from '../BodyText'
import {
  ButtonSize,
  getButtonHorizontalGap,
  getButtonMinHeight,
  getButtonPaddingHorizontal,
} from '../Button'
import { getDefaultButtonIconSize } from '../Button'
import { DropdownContext } from '../Dropdown'
import Icon, { Check, IconColor, SVGIcon } from '../Icon'
import VideoText from '../VideoText'

type Props = {
  label: React.ReactNode
  icon?: SVGIcon | null
  iconColor?: IconColor
  size?: ButtonSize
  sublabel?: React.ReactNode
  rightLabel?: React.ReactNode
  rightSublabel?: React.ReactNode
  rightIcon?: SVGIcon | null
  isDisabled?: boolean
  isSelected?: boolean
  isHighlighted?: boolean
  isRegularText?: boolean
  onPress?: ((event: GestureReponderEvent) => void) | null
  keepOpenOnSelect?: boolean
}

const DropdownListItem = forwardRef<HTMLButtonElement, Props>(
  (
    {
      label,
      sublabel,
      rightLabel,
      rightSublabel,
      rightIcon,
      isSelected,
      isHighlighted,
      size = 'md',
      icon,
      iconColor,
      isDisabled,
      isRegularText,
      onPress,
      keepOpenOnSelect,
    }: Props,
    ref
  ) => {
    const minHeight = getButtonMinHeight(size)
    const padding = getButtonPaddingHorizontal(size)
    const iconSize = getDefaultButtonIconSize(size)

    const { useInteractionsReturn, activeIndex, setIsOpen } = useContext(DropdownContext)
    const { getItemProps } = useInteractionsReturn
    const { ref: listItemRef, index } = useListItem()
    const isActive = activeIndex === index

    const mergedRefs = useMergeRefs([listItemRef, ref])

    return (
      // note: tag="button" improves accesibility, i.e. pressing enter to trigger onPress
      <XStack
        tag="button"
        role="option"
        key={index}
        tabIndex={isActive ? 0 : -1}
        gap="$4"
        minHeight={minHeight}
        padding={padding}
        alignItems="center"
        justifyContent="space-between"
        onPress={(e) => {
          if (!keepOpenOnSelect) {
            setIsOpen(false)
          }
          if (onPress) {
            onPress(e)
          }
        }}
        backgroundColor={isDisabled ? '$disabledBg' : isHighlighted ? '$pressBg' : undefined}
        maxWidth="100%"
        width="100%"
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        {...getItemProps()}
        ref={mergedRefs}
        focusStyle={{
          backgroundColor: !isDisabled ? '$pressBg' : undefined,
          outlineWidth: 0,
        }}
        disabled={isDisabled}
      >
        <XStack flexGrow={1} flexShrink={1} gap={getButtonHorizontalGap(size)} alignItems="center">
          {icon ? <Icon color={iconColor} icon={icon} size={iconSize} /> : null}
          <YStack maxWidth="100%">
            {isRegularText ? (
              <BodyText size={size}>{label}</BodyText>
            ) : (
              <VideoText size={size}>{label}</VideoText>
            )}
            {sublabel ? <BodyText color="secondary">{sublabel}</BodyText> : null}
          </YStack>
        </XStack>
        <XStack gap="$2" alignItems="center">
          {rightLabel ? (
            <YStack justifyContent="flex-end">
              {isRegularText ? (
                <BodyText textAlign="right" size={size}>
                  {rightLabel}
                </BodyText>
              ) : (
                <VideoText textAlign="right" size={size}>
                  {rightLabel}
                </VideoText>
              )}
              {rightSublabel ? (
                <BodyText textAlign="right" color="secondary">
                  {rightSublabel}
                </BodyText>
              ) : null}
            </YStack>
          ) : null}
          {rightIcon ? <Icon size={iconSize} icon={rightIcon} /> : null}
          {isSelected ? <Icon size={iconSize} icon={<Check />} /> : null}
        </XStack>
      </XStack>
    )
  }
)

DropdownListItem.displayName = 'DropdownListItem'

export default DropdownListItem
