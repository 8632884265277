import Icon, { IconProps } from '@lyra/core/components/Icon'
import ArbitrumIcon from '@lyra/core/components/Icon/ArbitrumIcon'
import BaseIcon from '@lyra/core/components/Icon/BaseIcon'
import EthereumIcon from '@lyra/core/components/Icon/EthereumIcon'
import OptimismIcon from '@lyra/core/components/Icon/OptimismIcon'
import { DepositNetwork } from '@lyra/web/constants/chains'
import React from 'react'

type Props = {
  network: DepositNetwork
} & Omit<IconProps, 'icon'>

const getIcon = (network: DepositNetwork): React.ReactElement => {
  switch (network) {
    case DepositNetwork.Arbitrum:
      return <ArbitrumIcon />
    case DepositNetwork.Ethereum:
      return <EthereumIcon />
    case DepositNetwork.Optimism:
      return <OptimismIcon />
    case DepositNetwork.Base:
      return <BaseIcon />
  }
}

export default function NetworkIcon({ network, ...iconProps }: Props): React.ReactNode {
  return <Icon {...iconProps} icon={getIcon(network)} />
}
