import useSWR from 'swr'

import { YieldPosition, YieldTokenId } from '../constants/yield'
import { fetchYieldPositions, getEmptyYieldPositions } from '../utils/yield'
import useAuth from './useAuth'

export default function useYieldPositions() {
  const { user } = useAuth()
  const ownerAddress = user?.ownerAddress

  return useSWR<Record<YieldTokenId, YieldPosition>>(
    ['YieldPositions', ownerAddress],
    () => (ownerAddress ? fetchYieldPositions(ownerAddress) : getEmptyYieldPositions()),
    {
      keepPreviousData: true,
    }
  )
}
