import { Address, Chain } from 'viem'

import getChainForChainId from './getChainForChainId'

const getExplorerUrl = (chainOrChainId: Chain | number, transactionHashOrAddress: Address) => {
  const chain =
    typeof chainOrChainId === 'number' ? getChainForChainId(chainOrChainId) : chainOrChainId
  if (!chain) {
    console.warn('Unexpected chain ID', chainOrChainId)
    return ''
  }
  const type = transactionHashOrAddress.length > 42 ? 'tx' : 'address'
  return `${chain.blockExplorers?.default.url}/${type}/${transactionHashOrAddress}`
}

export default getExplorerUrl
