import { Placement, Strategy, UseFloatingReturn, UseInteractionsReturn } from '@floating-ui/react'
import { ChevronDown, ChevronUp } from '@lyra/core/components/Icon'
import React, { createContext, forwardRef, useCallback, useState } from 'react'
import { StackProps, withStaticProperties } from 'tamagui'

import Button, { ButtonProps } from '../Button'
import Dropdown from '../Dropdown'
import DropdownClose from '../Dropdown/DropdownClose'
import DropdownListItem from '../Dropdown/DropdownListItem'

export interface DropdownButtonContextValue {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  useInteractionsReturn: UseInteractionsReturn
  useFloatingReturn: UseFloatingReturn
  activeIndex: number | null
}

export const DropdownButtonContext = createContext<DropdownButtonContextValue>(
  {} as DropdownButtonContextValue
)

export type DropdownButtonProps = {
  placement?: Placement
  strategy?: Strategy
  hideChevron?: boolean
  portalId?: string
  isPortaled?: boolean
  onChangeOpen?: (isOpen: boolean) => void
  dropdownProps?: StackProps
} & Omit<ButtonProps, 'iconAlignmnet'>

const DropdownButton = forwardRef<HTMLButtonElement, DropdownButtonProps>(
  (
    {
      dropdownProps,
      portalId,
      isPortaled = false,
      placement,
      strategy,
      hideChevron,
      children,
      onChangeOpen,
      ...buttonProps
    }: DropdownButtonProps,
    ref
  ) => {
    const { icon, label } = buttonProps
    const isIconOnly = !!icon && !label

    const [isOpen, setIsOpen] = useState(false)
    const [disableFocusStyle, setDisableFocusStyle] = useState(false)

    const handleOnChangeOpen = useCallback(
      (isOpen: boolean) => {
        setIsOpen(isOpen)
        if (onChangeOpen) {
          onChangeOpen(isOpen)
        }
      },
      [onChangeOpen]
    )

    return (
      <Dropdown
        placement={placement}
        strategy={strategy}
        portalId={portalId}
        onChangeOpen={handleOnChangeOpen}
        isPortaled={isPortaled}
        trigger={
          <Button
            ref={ref}
            icon={isIconOnly || hideChevron ? icon : isOpen ? <ChevronUp /> : <ChevronDown />}
            isSelected={isOpen}
            {...buttonProps}
          />
        }
        {...dropdownProps}
        focusStyle={{ outlineWidth: disableFocusStyle ? 0 : undefined }}
        onBlur={() => {
          // when focus is lost, reset the focus disable
          setDisableFocusStyle(false)
        }}
      >
        {children}
      </Dropdown>
    )
  }
)

DropdownButton.displayName = 'DropdownButton'

export default withStaticProperties(DropdownButton, {
  ListItem: DropdownListItem,
  // Wrap a component with <DropdownButton.Close> in order to close on press
  Close: DropdownClose,
})
