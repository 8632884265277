import React from 'react'
import { forwardRef } from 'react'
import { SizableText, SizableTextProps, useTheme } from 'tamagui'

import { getTextColorVar, TextColor } from '../utils/text'

export type SpanTextProps = {
  color?: TextColor
  customColor?: string
} & Omit<
  SizableTextProps,
  'fontFamily' | 'fontSize' | 'weight' | 'letterSpacing' | 'size' | 'color'
>

const SpanText = forwardRef<HTMLElement, SpanTextProps>(
  ({ color: colorInput = 'inherit', customColor, ...props }, ref) => {
    const theme = useTheme()
    const color = customColor
      ? customColor in theme
        ? theme[customColor]?.get()
        : customColor
      : getTextColorVar(colorInput)

    return (
      <SizableText
        {...props}
        tag="span"
        ref={ref}
        color={color}
        style={{
          // TODO: @earthtojake add more text props that need to be inherited
          fontFamily: 'inherit !important',
          letterSpacing: 'inherit !important',
          fontWeight: 'inherit !important',
          lineHeight: 'inherit !important',
        }}
      />
    )
  }
)

SpanText.displayName = 'SpanText'

export default SpanText
