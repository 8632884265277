'use client'

import { RpcError } from '@lyra/core/api/error'
import { OrderbookInstrumentNameNotificationSchema } from '@lyra/core/api/types/channel.orderbook.instrument_name'
import { SpotFeedCurrencyNotificationSchema } from '@lyra/core/api/types/channel.spot_feed.currency'
import { TickerInstrumentNameIntervalNotificationSchema } from '@lyra/core/api/types/channel.ticker.instrument_name.interval'
import { RPCError } from '@lyra/core/api/types/errors'
import { PublicLoginResponseSchema } from '@lyra/core/api/types/public.login'
import { SubscribeResponseSchema } from '@lyra/core/api/types/subscribe'
import { UnsubscribeResponseSchema } from '@lyra/core/api/types/unsubscribe'
import { createContext, useCallback, useEffect, useMemo, useRef } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'

import { orderbookWebsocketUrl } from '../constants/env'
import emptyFunction from '../utils/emptyFunction'

const SUBSCRIBE_MESSAGE_ID = 'ws-subscribe'
const UNSUBSCRIBE_MESSAGE_ID = 'ws-unsubscribe'

const EMPTY_WEBSOCKET_CONTEXT: WebSocketContext = {
  isReady: false,
  subscribe: emptyFunction as any,
  unsubscribe: emptyFunction as any,
}

const handleFilter = () => false

export const WebSocketContext = createContext<WebSocketContext>(EMPTY_WEBSOCKET_CONTEXT)

type MessageCallbackFn<T> = (msg: T) => void

type MessageId = string | number

type SubscribeCallbacks = {
  onMessage: MessageCallbackFn<any>
  onSubscribe?: () => void
  onUnsubscribe?: () => void
}

type SubscriptionRequest = {
  channel: string
} & SubscribeCallbacks

type SubscriptionCallbacksMap = {
  [channel: string]: SubscribeCallbacks[]
}

type MessageResponse =
  | {
      id?: MessageId
      method: 'subscription'
      params: {
        channel: string
        [key: string]: any
      }
    }
  | PublicLoginResponseSchema
  | SubscribeResponseSchema
  | UnsubscribeResponseSchema
  | NotificationSchema
  | RPCError

type NotificationSchema =
  | SpotFeedCurrencyNotificationSchema
  | TickerInstrumentNameIntervalNotificationSchema
  | OrderbookInstrumentNameNotificationSchema

export type WebSocketContext =
  | {
      isReady: false
      subscribe: undefined
      unsubscribe: undefined
    }
  | {
      isReady: true
      subscribe: (requests: SubscriptionRequest[]) => void
      unsubscribe: (channels: string[]) => void
    }

const isSubscribeResponse = (
  messageResponse: MessageResponse
): messageResponse is SubscribeResponseSchema => {
  return (
    'id' in messageResponse &&
    messageResponse.id === SUBSCRIBE_MESSAGE_ID &&
    'result' in messageResponse &&
    typeof messageResponse.result === 'object' &&
    'status' in messageResponse.result
  )
}

const isUnsubscribeResponse = (
  messageResponse: MessageResponse
): messageResponse is UnsubscribeResponseSchema => {
  return (
    'id' in messageResponse &&
    messageResponse.id === UNSUBSCRIBE_MESSAGE_ID &&
    'result' in messageResponse &&
    typeof messageResponse.result === 'object' &&
    'remaining_subscriptions' in messageResponse.result
  )
}

const isChannelMessage = (
  messageResponse: MessageResponse
): messageResponse is NotificationSchema => {
  return 'method' in messageResponse && messageResponse.method === 'subscription'
}

const isErrorMessage = (messageResponse: MessageResponse): messageResponse is RPCError => {
  return 'error' in messageResponse
}

export default function WebSocketProvider({ children }: { children: React.ReactNode }) {
  const subscriptionCallbacks = useRef<SubscriptionCallbacksMap>({})
  const subscriptionCounts = useRef<Record<string, number>>({})

  const handleMessage = useCallback((message: MessageEvent) => {
    if (message.data === 'pong') {
      return
    }
    const messageResponse: MessageResponse = JSON.parse(message.data)
    if (isSubscribeResponse(messageResponse)) {
      // Subscribed
      // console.debug('subscribed', {
      //   subscribedTo: Object.keys(messageResponse.result.status),
      //   currentSubscriptions: messageResponse.result.current_subscriptions,
      // })
      for (const channel in messageResponse.result.status) {
        const channelCallbacks = subscriptionCallbacks.current[channel]
        if (channelCallbacks) {
          for (const callbacks of channelCallbacks) {
            if (callbacks.onSubscribe) {
              callbacks.onSubscribe()
            }
          }
        }
      }
    } else if (isUnsubscribeResponse(messageResponse)) {
      // Unsubscribed
      // console.debug('unsubscribed', {
      //   unsubscribedTo: Object.keys(messageResponse.result.status),
      //   remainingSubscriptions: messageResponse.result.remaining_subscriptions,
      // })
      for (const channel in messageResponse.result.status) {
        const channelCallbacks = subscriptionCallbacks.current[channel]
        if (channelCallbacks) {
          for (const callbacks of channelCallbacks) {
            if (callbacks.onUnsubscribe) {
              callbacks.onUnsubscribe()
            }
          }
        }
      }
      // TODO: @earthtojake add unsubscribe handler once Josh adds status to unsubscribe response

      // Subscription messages
    } else if (isChannelMessage(messageResponse)) {
      const channelCallbacks = subscriptionCallbacks.current[messageResponse.params.channel]
      if (channelCallbacks) {
        channelCallbacks.forEach((callbacks) => {
          callbacks.onMessage(messageResponse.params.data)
        })
      }
    } else if (isErrorMessage(messageResponse)) {
      const { message, code, data } = messageResponse.error
      const orderbookError = new RpcError(message, code, data)
      if (
        orderbookError.code !== -32700 &&
        orderbookError.code !== -32602 &&
        orderbookError.code !== 403
      ) {
        // ignore parseError, Invalid params, 403 messages
        console.debug('ws orderbook error', { message, code, data })
        console.error(orderbookError)
      }
    }
  }, [])

  const { readyState, sendJsonMessage } = useWebSocket(orderbookWebsocketUrl, {
    onMessage: handleMessage,
    filter: handleFilter,
    shouldReconnect: () => true,
    reconnectAttempts: Infinity, // indefinite reconnection attempts
    reconnectInterval: 5000, // retry connection every 5 seconds
    retryOnError: true,
    heartbeat: {
      message: 'ping',
      returnMessage: 'pong',
      timeout: 10000, // 10 seconds
      interval: 5000, // every 5 seconds, a ping message will be sent
    },
  })

  const isReady = readyState === ReadyState.OPEN

  const subscribe = useCallback(
    (requests: SubscriptionRequest[]) => {
      if (!isReady || !requests.length) {
        return
      }
      const channels = requests.map((request) => request.channel)

      // IMPORTANT!! add callbacks before subscription, order matters
      requests.forEach(({ channel, ...callbacks }) => {
        if (!subscriptionCallbacks.current[channel]) {
          subscriptionCallbacks.current[channel] = []
        }
        subscriptionCallbacks.current[channel].push(callbacks)
      })

      // track duplicate subscriptions from different containers
      channels.forEach((channel) => {
        if (!subscriptionCounts.current[channel]) {
          subscriptionCounts.current[channel] = 1
        } else {
          subscriptionCounts.current[channel] += 1
        }
      })
      const newChannels = channels.filter((channel) => subscriptionCounts.current[channel] === 1)

      // console.debug('subscribe', { channels, newChannels, subscriptionCounts })

      sendJsonMessage({
        id: SUBSCRIBE_MESSAGE_ID,
        method: 'subscribe',
        params: {
          channels: newChannels,
        },
      })
    },
    [isReady, sendJsonMessage]
  )

  const unsubscribe = useCallback(
    (channels: string[]) => {
      if (!isReady || !channels.length) {
        return
      }

      const unsubscribeChannels: string[] = []
      channels.forEach((channel) => {
        const subscriptionCount = subscriptionCounts.current[channel]
        if (subscriptionCount === 1 || !subscriptionCount) {
          unsubscribeChannels.push(channel)
          subscriptionCounts.current[channel] = 0
        } else if (subscriptionCount > 1) {
          subscriptionCounts.current[channel] -= 1
        }
      })

      // console.debug('unsubscribe', unsubscribeChannels)

      sendJsonMessage({
        id: UNSUBSCRIBE_MESSAGE_ID,
        method: 'unsubscribe',
        params: { channels: unsubscribeChannels },
      })
    },
    [isReady, sendJsonMessage]
  )

  // resubscribe to all channels
  // used when app comes back into focus or websocket reconnects
  const resubscribe = useCallback(() => {
    if (!isReady) {
      return
    }
    const channels = Object.entries(subscriptionCounts.current)
      .filter(([_0, count]) => count > 0)
      .map(([channel]) => channel)
    sendJsonMessage({
      id: SUBSCRIBE_MESSAGE_ID,
      method: 'subscribe',
      params: {
        channels,
      },
    })
  }, [isReady, sendJsonMessage])

  /**
   * LISTENERS
   */

  // resubscribe when isReady state changes
  // e.g. after device goes offline and websocket reconnects
  const prevIsReady = useRef(false)
  const isMounted = useRef(false)
  useEffect(() => {
    if (isMounted.current && !prevIsReady.current && isReady) {
      // resubscribe when isReady changes
      // happens on reconnection
      resubscribe()
    }
    isMounted.current = true
    prevIsReady.current = isReady
  }, [resubscribe, isReady])

  const value: WebSocketContext = useMemo(
    () =>
      isReady
        ? {
            isReady: true,
            subscribe,
            unsubscribe,
          }
        : {
            isReady: false,
            subscribe: undefined,
            unsubscribe: undefined,
          },
    [isReady, subscribe, unsubscribe]
  )

  return <WebSocketContext.Provider value={value}>{children}</WebSocketContext.Provider>
}
