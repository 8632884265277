'use client'

import { SpotFeedCurrencyPublisherDataSchema } from '@lyra/core/api/types/channel.spot_feed.currency'
import { CurrencyResponseSchema } from '@lyra/core/api/types/public.get_all_currencies'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { EMPTY_MARKETS, Market, marketsConfig } from '../constants/markets'
import { MarketId } from '../constants/markets'
import {
  Collateral,
  collateralConfig,
  CollateralId,
  CurrencyId,
  EMPTY_COLLATERALS,
} from '../constants/tokens'
import useWebSocket from '../hooks/useWebSocket'
import emptyFunction from '../utils/emptyFunction'
import { getMarketId, getMarketsFromCurrencies } from '../utils/markets'
import { getCollateralsFromCurrencies } from '../utils/tokens'
import { coerce } from '../utils/types'

type Props = {
  initialCurrencies: CurrencyResponseSchema[]
  children?: React.ReactNode
}

export type MarketContext = {
  markets: Record<MarketId, Market>
  collaterals: Record<CollateralId, Collateral>
  currencies: Record<CurrencyId, Market | Collateral>
  getMarkets: () => Record<MarketId, Market>
  getCollaterals: () => Record<CollateralId, Collateral>
  getCurrencies: () => Record<CurrencyId, Market | Collateral>
}

export const MarketContext = React.createContext<MarketContext>({
  markets: EMPTY_MARKETS,
  collaterals: EMPTY_COLLATERALS,
  currencies: { ...EMPTY_MARKETS, ...EMPTY_COLLATERALS },
  getMarkets: emptyFunction as any,
  getCollaterals: emptyFunction as any,
  getCurrencies: emptyFunction as any,
})

const POLL_MS = 1000

const getSpotFeedChannel = (currency: string) => {
  return `spot_feed.${currency}`
}

const getCurrenciesDict = (currencies: CurrencyResponseSchema[]) =>
  currencies.reduce(
    (dict, currency) => ({ ...dict, [currency.currency]: currency }),
    {} as Record<string, CurrencyResponseSchema>
  )

export default function MarketProvider({ initialCurrencies, children }: Props) {
  const { isReady, subscribe } = useWebSocket()

  const initialCurrenciesDict = useRef(getCurrenciesDict(initialCurrencies)).current

  const [collaterals, setCollaterals] = useState<Record<CollateralId, Collateral>>(
    getCollateralsFromCurrencies(initialCurrencies)
  )
  const collateralsRef = useRef<Record<CollateralId, Collateral>>(collaterals)

  const [markets, setMarkets] = useState<Record<MarketId, Market>>(
    getMarketsFromCurrencies(initialCurrencies)
  )
  const marketsRef = useRef<Record<MarketId, Market>>(markets)

  const isSubscribed = useRef(false)

  useEffect(() => {
    if (isReady && !isSubscribed.current) {
      isSubscribed.current = true
      const channels = Object.values(MarketId).map((marketId) => getSpotFeedChannel(marketId))
      subscribe(
        channels.map((channel) => ({
          channel,
          onMessage: (spotFeedData: SpotFeedCurrencyPublisherDataSchema) => {
            for (const currency in spotFeedData.feeds) {
              const spotPrice = +spotFeedData.feeds[currency].price
              const spotPrice24hAgo =
                currency in initialCurrenciesDict
                  ? +initialCurrenciesDict[currency].spot_price_24h
                  : 0

              const spotPrice24hChange = spotPrice - spotPrice24hAgo
              const spotPrice24hPctChange =
                spotPrice24hAgo !== 0 ? (spotPrice - spotPrice24hAgo) / spotPrice24hAgo : 0

              const priceData = {
                spotPrice,
                spotPrice24hAgo,
                spotPrice24hChange,
                spotPrice24hPctChange,
              }

              const marketId = getMarketId(currency)
              if (marketId) {
                marketsRef.current[marketId] = {
                  ...marketsConfig[marketId],
                  ...priceData,
                }
              }

              const collateralId = coerce(CollateralId, currency)
              if (collateralId) {
                collateralsRef.current[collateralId] = {
                  ...collateralConfig[collateralId],
                  ...priceData,
                }
              }
            }
          },
        }))
      )
    }
  }, [initialCurrenciesDict, isReady, subscribe])

  useEffect(() => {
    const interval = setInterval(() => {
      const newCollateralsRef = { ...collateralsRef.current }
      setCollaterals(newCollateralsRef)

      const newMarketsRef = { ...marketsRef.current }
      setMarkets(newMarketsRef)
    }, POLL_MS)
    return () => clearInterval(interval)
  }, [])

  // returns references
  const getMarkets = useCallback(() => {
    return markets
    // IMPORTANT!! do not trigger re-render on getMarkets
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // returns references
  const getCollaterals = useCallback(() => {
    return collaterals
    // IMPORTANT!! do not trigger re-render on getMarkets
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // returns references
  const getCurrencies = useCallback(() => {
    return { ...markets, ...collaterals }
    // IMPORTANT!! do not trigger re-render on getMarkets
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const currencies = useMemo(() => ({ ...markets, ...collaterals }), [markets, collaterals])

  const value = useMemo(
    () => ({ markets, collaterals, currencies, getMarkets, getCollaterals, getCurrencies }),
    [markets, collaterals, currencies, getMarkets, getCollaterals, getCurrencies]
  )

  return <MarketContext.Provider value={value}>{children}</MarketContext.Provider>
}
